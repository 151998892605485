import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import { useHashConnect } from "../api/HashConnectAPIProvider.tsx";
import { setOfferData } from "../store/offerInfo";

import {
  MAIN_BUTTON_COLOR,
  MAIN_BUTTON_BORDER_COLOR,
  GREEN_FONT_COLOR,
  TWEET_BUTTON_COLOR,
} from "../value/colors";

import { DEFAULT_FONT } from "../value/fonts";
import { GIFT_NFT_ID, TREASURY_ID } from "../value/values";
import { LINK_TYPE_EMAIL, LINK_TYPE_TWITTER } from "../value/strings";
import { sleep } from "../api/mainFunctions";
import { getRequest, postRequest } from "../api/apiRequests";
import { MIRROR_NET_URL, SERVER_URL } from "../value/urls";

function CreateSuccess() {
  let history = useHistory();
  const [cookies, setCookie] = useCookies(["user"]);

  const dispatch = useDispatch();
  const offerData = useSelector((store) => store.offer.offerData);
  const [loadingViewFlag, setLoadingViewFlag] = useState(false);
  const { walletData, allowanceSendHbar } = useHashConnect();
  const { accountIds } = walletData;

  const [signInInfo, setSignInInfo] = useState({});

  const [createdNftData, setCreatedNftData] = useState({});
  const [giftPrice, setGiftPrice] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [timestampLoadFlag, setTimestampLoadFlag] = useState(false);

  useEffect(() => {
    if (cookies.user && Object.keys(cookies.user).length > 0) {
      console.log('CreateSuccess useEffect log - 1 : ', cookies.user);
      const newSignInInfo = {
        linkType: LINK_TYPE_EMAIL,
        id: cookies.user.email,
      };
      setSignInInfo(newSignInInfo);
      console.log("CreateSuccess useEffect log - 2 : ", offerData);
      getOfferData(offerData, newSignInInfo);
    } else {
      notSigned();
    }
  }, [offerData, cookies]);

  const notSigned = async () => {
    setLoadingViewFlag(true);
    toast.warning('Please sign in with Twitter or Email.');
    await sleep(2000);
    history.push('/main');
  }
  const getOfferData = async (offerData, signInInfo_g) => {
    console.log('getOfferData log - 1 : ', offerData, signInInfo_g);
    if (Object.keys(offerData).length === 0) {
      toast.error("Create gift data is empty!");
      await sleep(2000);
      history.push("/main");
    }
    setCreatedNftData(offerData.nftData);
    setGiftPrice(offerData.giftPrice);
    setReceiverId(offerData.receiverId);
    sendMintOffer(offerData.nftData, offerData.giftPrice, offerData.receiverId, signInInfo_g);
  };

  const sendMintOffer = async (nftData, amount, receiverId, signInInfo_s) => {
    console.log("sendMintOffer log - 1", nftData, amount, receiverId, signInInfo_s);
    setLoadingViewFlag(true);

    if (!accountIds) {
      toast.error("HashPack wallet not connected!");
      await sleep(2000);
      history.push("/main");
    }

    const allowanceResult = await allowanceSendHbar(TREASURY_ID, amount);
    if (!allowanceResult) {
      toast.error("A problem detected with allowance.");
      await sleep(2000);
      history.push("/create");
      return;
    }

    const mintResult = await postRequest(
      SERVER_URL + "/gifts_info/send_offer",
      {
        linkType: btoa(signInInfo_s.linkType),
        senderId: btoa(signInInfo_s.id),
        senderWalletId: btoa(accountIds[0]),
        amount: btoa(amount),
        receiverId: btoa(receiverId),
        metadataCID: btoa(nftData.metadataCID),
      }
    );

    console.log("sendMintOffer log - 2", mintResult);
    if (!mintResult.result) {
      toast.error(mintResult.error);
      await sleep(2000);
      history.push("/create");
    }

    setTimestampLoadFlag(false);
    getCreateLink(GIFT_NFT_ID, mintResult.data.serialNum);

    toast.success("Your gift has been sent successfully!");
    setLoadingViewFlag(false);
  };

  const getCreateLink = async (tokenId, serialNum) => {
    setTimestamp("loading...");
    while (1) {
      if (timestampLoadFlag) return;
      const tempTimestamp = await getCreatedTimestamp(tokenId, serialNum);
      if (tempTimestamp !== "") {
        setTimestamp(tempTimestamp);
        setTimestampLoadFlag(true);
        return;
      }
      await sleep(1000);
    }
  };

  const getCreatedTimestamp = async (tokenId, serialNum) => {
    console.log("getTimestamp log - 1 : ", tokenId, serialNum);
    if (timestampLoadFlag) return "";
    try {
      const res = await getRequest(
        `${MIRROR_NET_URL}/tokens/${tokenId}/nfts/${serialNum}`
      );
      console.log("getTimestamp log - 2 : ", res);
      if (res.created_timestamp) return res.created_timestamp;
      return "";
    } catch (error) {
      return "";
    }
  };

  const handleEmailClick = async () => {
    const info = await postRequest(`${SERVER_URL}/email/send_message`, {
      // senderId: senderId,
      receiverId: receiverId,
      message: "I sent you presents.",
    });
    console.log(">>>>>>>>>>>>handleEmailClick", info);

    if (info.result) {
      toast.success("Your message is sent successfully");
    }
  };

  const handleTwitterClick = async () => {
    const info = await postRequest(`${SERVER_URL}/twitter/send_message`, {
      user: receiverId,
      message: "My Message",
    });
    console.log(">>>>>>>>>>>>handleTwitterClick", info);

    if (info.result) {
      toast.success("Your message is sent successfully");
    }
  };

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "50vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "35vh 0 15vh",
        }}
      >
        <Button
          sx={{
            width: 240,
            height: 240,
            minWidth: 240,
            minHeight: 240,
            background: `url(${createdNftData.imageUrl})`,
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            border: "10px solid",
            borderColor: MAIN_BUTTON_BORDER_COLOR,
            borderRadius: 0,
            margin: "0 10px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: "5px",
              top: "5px",
              margin: 0,
              padding: 0,
              borderRadius: "20px",
              color: "white",
              boxShadow: "none",
              backgroundColor: MAIN_BUTTON_COLOR,
              fontSize: "12px",
              "&:hover": {
                backgroundColor: MAIN_BUTTON_COLOR,
                boxShadow: "none",
              },
              "&:focus": {
                boxShadow: "none",
              },
            }}
          >
            {giftPrice} Hbar
          </Button>
        </Button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: MAIN_BUTTON_COLOR,
              fontFamily: DEFAULT_FONT,
              fontSize: 20,
              fontWeight: 700,
              margin: 0,
              zIndex: 10,
            }}
          >
            hashpack transaction:
            <a
              onClick={() => {
                if (setTimestampLoadFlag)
                  window.open(
                    `https://hashscan.io/mainnet/transaction/${timestamp}`,
                    "_blank"
                  );
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              {setTimestampLoadFlag ? timestamp : "loading..."}
            </a>
          </p>
          <p
            style={{
              color: "white",
              fontSize: 24,
              margin: 0,
              textAlign: "center",
              fontFamily: DEFAULT_FONT,
              marginTop: "10px",
            }}
          >
            Your NFT Gift Card Code has been generated
            <span
              style={{
                color: GREEN_FONT_COLOR,
                margin: "0 5px",
              }}
            >
              successfully.
            </span>
            Copy, paste and share this link on Twitter to alert the user for
            redemption!
          </p>
        </div>
        <Button
          variant="contained"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 180,
            padding: "10px 0",
            backgroundColor: TWEET_BUTTON_COLOR,
            fontFamily: DEFAULT_FONT,
            "&:hover": {
              backgroundColor: TWEET_BUTTON_COLOR,
            },
          }}
          // onClick={() => handleTwitterClick()}
          onClick={() => handleEmailClick()}
        >
          {
            signInInfo.linkType === LINK_TYPE_TWITTER &&
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="white"
              style={{
                width: 48,
                height: 48,
                marginTop: "5px",
              }}
            >
              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
            </svg>
          }
          {
            signInInfo.linkType === LINK_TYPE_EMAIL &&
            <div style={{
              width: 48,
              height: 48,
              marginRight: '5px',
              background: 'url(./images/gmail.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
            />
          }
          <p
            style={{
              color: "white",
              fontSize: 14,
              margin: "5px 0",
            }}
          >
            {signInInfo.linkType === LINK_TYPE_TWITTER ? 'CLICK TO TWEET' : 'CLICK TO SEND EMAIL'}
          </p>
        </Button>
      </div>
      <Backdrop
        sx={{
          backgroundColor: "black",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingViewFlag}
      >
        <div
          style={{
            width: 250,
            height: 225,
            backgroundImage: "url(./images/loading.gif)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        />
      </Backdrop>
      <ToastContainer autoClose={3000} draggableDirection="x" />
    </>
  );
}

export default CreateSuccess;
