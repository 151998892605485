import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, IconButton, Input, Backdrop } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { useHashConnect } from '../api/HashConnectAPIProvider.tsx';

import { MAIN_BUTTON_COLOR, MAIN_BUTTON_BORDER_COLOR, INPUT_FONT_COLOR, GREEN_FONT_COLOR } from '../value/colors';
import { SERVER_URL } from '../value/urls';
import { getRequest } from '../api/apiRequests';
import { sleep } from '../api/mainFunctions';

import { setOfferData } from '../store/offerInfo';
import { LINK_TYPE_EMAIL } from '../value/strings';

const INPUT_STYLE = {
    width: 240,
    height: 42,
    border: '3px solid',
    borderColor: MAIN_BUTTON_COLOR,
    color: INPUT_FONT_COLOR,
    padding: '0 5px',
    '&::before': {
        display: 'none',
    },
    '&::after': {
        display: 'none',
    },
};

function CreateGift() {
    let history = useHistory();
    const [cookies, setCookie] = useCookies(["user"]);

    const dispatch = useDispatch();

    const [loadingViewFlag, setLoadingViewFlag] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);

    const { walletData } = useHashConnect();
    const { accountIds } = walletData;

    const [signInInfo, setSignInInfo] = useState({});

    const [nftData, setNftData] = useState([]);

    const [hbarQuantity, setHbarQuantity] = useState('');
    const [receiverId, setReceiverId] = useState('');

    useEffect(() => {
        if (cookies.user && Object.keys(cookies.user).length > 0) {
            console.log('CreateGift useEffect log - 1 : ', cookies.user);
            setSignInInfo({
                linkType: LINK_TYPE_EMAIL,
                id: cookies.user.email,
            });
            console.log('CreateGift useEffect log - 1');
            getUsefulNftData();
        } else {
            notSigned();
        }
    }, [cookies]);

    const notSigned = async () => {
        setLoadingViewFlag(true);
        toast.warning('Please sign in with Twitter or Email.');
        await sleep(2000);
        history.push('/main');
    }
    const getUsefulNftData = async () => {
        setLoadingViewFlag(true);
        console.log('getUsefulNftData log - 1');
        let usefulNftData = await getRequest(SERVER_URL + '/nft_data/get_useful_nft_data');
        console.log('getUsefulNftData log - 2 : ', usefulNftData);

        if (!usefulNftData.result) {
            console.log('getUsefulNftData log - 3 : ', usefulNftData.error);
            toast.error(usefulNftData.error);
            await sleep(2000);
            history.push('/main');
        }

        for (let i = 0; i < usefulNftData.data.length; i++) {
            usefulNftData.data[i].selected = i === 0 ? true : false;
        }
        console.log('getUsefulNftData log - 4 : ', usefulNftData.data);
        setNftData(usefulNftData.data);
        setRefreshFlag(!refreshFlag);
        setLoadingViewFlag(false);
    }

    const onClickNftPreviewBtn = (index) => {
        console.log('onClickNftPreviewBtn log - 1 : ', index);
        let tempNftData = nftData;
        for (let i = 0; i < tempNftData.length; i++) {
            tempNftData[i].selected = i === index ? true : false;
        }
        setNftData(tempNftData);
        setRefreshFlag(!refreshFlag);
    }

    const onClickSendGiftBtn = () => {
        console.log('onClickSendGiftBtn log - 1');
        if (!accountIds) {
            toast.warning('Connect Wallet please!');
            return;
        }
        if (hbarQuantity === '') {
            toast.warning('Please enter hbar quantity!');
            return;
        }
        if (receiverId === '') {
            toast.warning('Please enter user Twitter or Email!');
            return;
        }

        const selectedNftData = nftData.filter(item => item.selected === true);
        console.log('onClickSendGiftBtn log - 2 : ', selectedNftData);
        if (selectedNftData.length === 0) {
            toast.warning('Please select NFT image!');
            return;
        }

        dispatch(setOfferData({
            giftPrice: hbarQuantity,
            receiverId: receiverId,
            nftData: selectedNftData[0],
        }));

        history.push('/success');
    }

    return (
        <>
            <div style={{
                width: '100vw',
                height: '50vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '35vh 0 15vh',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {
                        nftData.map((item, index) => {
                            return <Button onClick={() => onClickNftPreviewBtn(index)}
                                sx={{
                                    width: 240,
                                    height: 240,
                                    minWidth: 240,
                                    minHeight: 240,
                                    background: `url(${item.imageUrl})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    border: '10px solid',
                                    borderColor: `${item.selected ? MAIN_BUTTON_COLOR : MAIN_BUTTON_BORDER_COLOR}`,
                                    borderRadius: 0,
                                    margin: '0 10px',
                                    transition: 'all 1s',
                                    '&:hover': {
                                        rotate: '-5deg',
                                        transition: 'all 0.3s',
                                    },
                                }} />
                        })
                    }
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100,
                    justifyContent: 'space-between',
                }}>
                    <Input placeholder='HBAR QUANTITY'
                        type='number'
                        value={hbarQuantity}
                        onChange={(e) => { setHbarQuantity(e.target.value) }}
                        sx={INPUT_STYLE} />
                    <Input placeholder={signInInfo.linkType === LINK_TYPE_EMAIL ? 'RECEIVER EMAIL' : 'RECEIVER TWITTER'}
                        value={receiverId}
                        onChange={(e) => { setReceiverId(e.target.value) }}
                        sx={INPUT_STYLE} />
                </div>
                <IconButton onClick={() => onClickSendGiftBtn()}
                    sx={{
                        padding: '5px',
                        color: GREEN_FONT_COLOR
                    }}>
                    <PlayCircleOutlineIcon sx={{
                        width: 80,
                        height: 80,
                    }} />
                </IconButton>
            </div>
            <Backdrop
                sx={{
                    backgroundColor: 'black',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loadingViewFlag}
            >
                <div style={{
                    width: 250,
                    height: 225,
                    backgroundImage: 'url(./images/loading.gif)',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }} />
            </Backdrop>
            <ToastContainer autoClose={3000} draggableDirection='x' />
        </>
    );
}

export default CreateGift;