import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Box, Button, IconButton, Input, Backdrop } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { useHashConnect } from '../api/HashConnectAPIProvider.tsx';

import { MAIN_BUTTON_COLOR, MAIN_BUTTON_BORDER_COLOR, INPUT_FONT_COLOR, GREEN_FONT_COLOR } from '../value/colors';
import { MIRROR_NET_URL, SERVER_URL } from '../value/urls';
import { GIFT_NFT_ID, TREASURY_ID } from '../value/values';
import { getRequest, postRequest } from '../api/apiRequests';
import { sleep } from '../api/mainFunctions';

import { setOfferData } from '../store/offerInfo';
import { LINK_TYPE_EMAIL, LINK_TYPE_TWITTER } from '../value/strings';
import { DEFAULT_FONT } from '../value/fonts';

const GIFTS_IN_TREASURY = 'treasury';
const GIFTS_IN_WALLET = 'wallet';

function Claim() {
    let history = useHistory();
    const [cookies, setCookie] = useCookies(['user']);

    const [loadingViewFlag, setLoadingViewFlag] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);

    const { walletData, allowanceSendNft } = useHashConnect();
    const { accountIds } = walletData;

    const [signInInfo, setSignInInfo] = useState({});

    const [giftsFrom, setGiftsFrom] = useState(GIFTS_IN_TREASURY);
    const [treasuryNftData, setTreasuryNftData] = useState([]);
    const [walletNftData, setWalletNftData] = useState([]);

    useEffect(() => {
        if (cookies.user && Object.keys(cookies.user).length > 0) {
            console.log('Claim useEffect log - 1 : ', cookies.user);
            const newSignInInfo = {
                linkType: LINK_TYPE_EMAIL,
                id: cookies.user.email,
            };
            setSignInInfo(newSignInInfo);
            getNftInfos(newSignInInfo);
            if (accountIds?.length > 0) {
                getWalletNftData();
            }
        } else {
            notSigned();
        }
    }, [accountIds, cookies]);

    const notSigned = async () => {
        setLoadingViewFlag(true);
        toast.warning('Please sign in with Twitter or Email.');
        await sleep(2000);
        history.push('/main');
    }

    const getWalletNftData = async () => {
        console.log("getWalletNftData log - 1 ");

        if (!accountIds) return;

        setLoadingViewFlag(true);

        let nextLink = null;
        let newWalletNftInfo = [];

        let wNftInfo = await getRequest('https://mainnet-public.mirrornode.hedera.com/api/v1/accounts/' + accountIds[0] + '/nfts');
        console.log("getWalletNftData log - 2 : ", wNftInfo);
        if (wNftInfo && wNftInfo.nfts.length > 0)
            nextLink = wNftInfo.links.next;

        while (1) {
            let _tempNftInfo = wNftInfo.nfts;

            for (let i = 0; i < _tempNftInfo.length; i++) {
                if (_tempNftInfo[i].token_id === GIFT_NFT_ID) {
                    const _singleNftInfo = await getRequest(`${SERVER_URL}/gifts_info/get_single_gift?serialNum=${btoa(_tempNftInfo[i].serial_number)}`);

                    console.log("getWalletNftData log - 3 : ", _singleNftInfo);

                    if (_singleNftInfo.result) {

                        console.log("getWalletNftData log - 4.1 : ", newWalletNftInfo);
                        console.log("getWalletNftData log - 4.2 : ", _singleNftInfo.data.serialNum);
                        const findDuplicate = newWalletNftInfo.filter(item => item.serialNum === _singleNftInfo.data.serialNum);
                        console.log("getWalletNftData log - 4.3 : ", findDuplicate);

                        if (findDuplicate.length === 0) {
                            newWalletNftInfo.push({
                                linkType: _singleNftInfo.data.linkType,
                                senderId: _singleNftInfo.data.senderId,
                                serialNum: _singleNftInfo.data.serialNum,
                                metadataCID: _singleNftInfo.data.metadataCID,
                                imageUrl: _singleNftInfo.data.imageUrl,
                                hbarAmount: _singleNftInfo.data.hbarAmount,
                            });
                        }
                    }
                }
            }

            if (!nextLink || nextLink === null) break;

            wNftInfo = await getRequest('https://mainnet-public.mirrornode.hedera.com' + nextLink);
            nextLink = null;
            if (wNftInfo && wNftInfo.nfts.length > 0)
                nextLink = wNftInfo.links.next;
        }
        console.log("getWalletNftData log - 5 : ", newWalletNftInfo);
        setWalletNftData(newWalletNftInfo);
        setRefreshFlag(!refreshFlag);
        setLoadingViewFlag(false);
    }

    const getNftInfos = async (signInInfo_g) => {
        console.log('getNftInfo log - 1 ');
        setLoadingViewFlag(true);
        const getResult = await getRequest(`${SERVER_URL}/gifts_info/get_gift?linkType=${btoa(signInInfo_g.linkType)}&receiverId=${btoa(signInInfo_g.id)}`);
        console.log('getNftInfo log - 2 : ', getResult);
        if (!getResult.result) {
            toast.error(getResult.error);
            await sleep(2000);
            history.push('/main');
            setLoadingViewFlag(false);
        }

        let tempNftData = [];
        for (let i = 0; i < getResult.data.length; i++) {
            tempNftData.push({
                linkType: getResult.data[i].linkType,
                senderId: getResult.data[i].senderId,
                serialNum: getResult.data[i].serialNum,
                metadataCID: getResult.data[i].metadataCID,
                imageUrl: getResult.data[i].imageUrl,
                hbarAmount: getResult.data[i].hbarAmount,
            })
        }

        console.log('getNftInfo log - 3 : ', tempNftData);
        setTreasuryNftData(tempNftData);
        setRefreshFlag(!refreshFlag);
        setLoadingViewFlag(false);
    }

    const onClickBurnAndClaim = async (item) => {
        console.log('onClickBurnAndClaim log - 1 : ', item);
        setLoadingViewFlag(true);

        if (!accountIds) {
            toast.warning('Connect Wallet to claim!');
            setLoadingViewFlag(false);
            return;
        }

        const burnAndClaimResult = await postRequest(SERVER_URL + '/gifts_info/burn_and_claim_gift', {
            receiverId: btoa(signInInfo.id),
            serialNum: btoa(item.serialNum),
            walletId: btoa(accountIds[0]),
        });

        if (!burnAndClaimResult.result) {
            toast.error(burnAndClaimResult.error);
            setLoadingViewFlag(false);
            return;
        }

        toast.success('Burn NFT & Claim Hbar successful!. Check your wallet please.');
        await getNftInfos(signInInfo);
        await getWalletNftData();

        setLoadingViewFlag(false);
    }

    const onClickSendBurnAndClaim = async (item) => {
        console.log('onClickSendBurnAndClaim log - 1 : ', item);
        setLoadingViewFlag(true);
        const allowanceResult = await allowanceSendNft(TREASURY_ID, GIFT_NFT_ID, item.serialNum);
        if (!allowanceResult) {
            toast.error('NFT allowance failed!');
            setLoadingViewFlag(false);
            return;
        }

        const sendNftResult = await postRequest(SERVER_URL + '/gifts_info/receive_nft', {
            serialNum: btoa(item.serialNum),
            walletId: btoa(accountIds[0]),
        });
        console.log('onClickSendBurnAndClaim log - 2 : ', sendNftResult);
        if (!sendNftResult.result) {
            toast.error(sendNftResult.error);
            setLoadingViewFlag(false);
            return;
        }

        await onClickBurnAndClaim(item);
    }

    const onclickClaimNft = async (item) => {
        console.log('onClickClaimNft log - 1 : ', item);
        setLoadingViewFlag(true);

        if (!accountIds) {
            toast.warning('Connect Wallet to claim!');
            setLoadingViewFlag(false);
            return;
        }

        const claimResult = await postRequest(SERVER_URL + '/gifts_info/claim_nft', {
            receiverId: btoa(signInInfo.id),
            serialNum: btoa(item.serialNum),
            walletId: btoa(accountIds[0]),
        });

        if (!claimResult.result) {
            toast.error(claimResult.error);
            setLoadingViewFlag(false);
            return;
        }

        await getNftInfos(signInInfo);
        await getWalletNftData();
        toast.success('Claim NFT successful!. Check your wallet please.');
        
        setLoadingViewFlag(false);
    }

    return (
        <>
            <div style={{
                width: '100vw',
                height: '50vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '35vh 0 15vh',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <Button onClick={() => { setGiftsFrom(GIFTS_IN_TREASURY) }}
                        variant='outlined'
                        sx={{
                            backgroundColor: `${giftsFrom === GIFTS_IN_TREASURY ? MAIN_BUTTON_COLOR : 'transparent'}`,
                            borderColor: MAIN_BUTTON_COLOR,
                            color: 'white',
                            width: '180px',
                            height: '42px',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderTopLeftRadius: '21px',
                            borderBottomLeftRadius: '21px',
                            fontFamily: DEFAULT_FONT,
                            '&:hover': {
                                backgroundColor: `${giftsFrom === GIFTS_IN_TREASURY ? MAIN_BUTTON_COLOR : 'transparent'}`,
                                borderColor: MAIN_BUTTON_COLOR,
                                color: 'white',
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}>
                        In Treasury
                    </Button>
                    <Button onClick={() => { setGiftsFrom(GIFTS_IN_WALLET) }}
                        variant='outlined'
                        sx={{
                            backgroundColor: `${giftsFrom === GIFTS_IN_WALLET ? MAIN_BUTTON_COLOR : 'transparent'}`,
                            borderColor: MAIN_BUTTON_COLOR,
                            color: 'white',
                            width: '180px',
                            height: '42px',
                            borderTopRightRadius: '21px',
                            borderBottomRightRadius: '21px',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            fontFamily: DEFAULT_FONT,
                            '&:hover': {
                                backgroundColor: `${giftsFrom === GIFTS_IN_WALLET ? MAIN_BUTTON_COLOR : 'transparent'}`,
                                borderColor: MAIN_BUTTON_COLOR,
                                color: 'white',
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}>
                        In My Wallet
                    </Button>
                </Box>
                <Box sx={{
                    width: 'fit-content',
                    maxWidth: '100vw',
                    paddingBottom: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                        height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                        borderRadius: '100px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(200, 200, 200, 0.5)',
                        borderRadius: '100px',
                    },
                }}>
                    {
                        giftsFrom === GIFTS_IN_TREASURY &&
                        treasuryNftData.length > 0 &&
                        treasuryNftData.map((item, index) => {
                            return <Box sx={{
                                position: 'relative',
                                width: 240,
                                height: 330,
                                minWidth: 240,
                                minHeight: 240,
                                backgroundColor: 'gray',
                                backgroundImage: `url(${item.imageUrl})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'top',
                                backgroundRepeat: 'no-repeat',
                                border: '10px solid',
                                borderColor: MAIN_BUTTON_BORDER_COLOR,
                                borderRadius: 0,
                                margin: '0 10px',
                            }}>
                                <Box sx={{
                                    width: 120,
                                    padding: '5px 10px',
                                    position: 'absolute',
                                    bottom: '90px',
                                    left: 0,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    backgroundColor: 'gray',
                                    borderTopRightRadius: '32px',
                                }}>
                                    {
                                        item.linkType === LINK_TYPE_TWITTER &&
                                        <svg xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'
                                            fill='white'
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}>
                                            <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z' />
                                        </svg>
                                    }
                                    {
                                        item.linkType === LINK_TYPE_EMAIL &&
                                        <Box
                                            style={{
                                                width: 20,
                                                height: 20,
                                                background: "url(./images/gmail.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        />
                                    }
                                    <p style={{
                                        width: 90,
                                        margin: '0 0 0 5px',
                                        color: 'white',
                                        fontFamily: DEFAULT_FONT,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>{item.senderId}</p>
                                </Box>
                                <Button variant='contained'
                                    sx={{
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                        margin: 0,
                                        padding: 0,
                                        borderRadius: '20px',
                                        color: 'white',
                                        boxShadow: 'none',
                                        backgroundColor: MAIN_BUTTON_COLOR,
                                        fontSize: '12px',
                                        fontFamily: DEFAULT_FONT,
                                        '&:hover': {
                                            backgroundColor: MAIN_BUTTON_COLOR,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            boxShadow: 'none',
                                        },
                                    }}>
                                    {item.hbarAmount} Hbar
                                </Button>
                                <Box sx={{
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    <Button onClick={() => onClickBurnAndClaim(item)}
                                        variant='contained'
                                        sx={{
                                            height: '32px',
                                            margin: '4px 0',
                                            textTransform: 'none',
                                            backgroundColor: MAIN_BUTTON_COLOR,
                                            boxShadow: 'none',
                                            borderRadius: '16px',
                                            fontFamily: DEFAULT_FONT,
                                            '&:hover': {
                                                backgroundColor: MAIN_BUTTON_COLOR,
                                                boxShadow: 'none',
                                            },
                                            '&:focus': {
                                                boxShadow: 'none',
                                            },
                                        }}>
                                        Burn NFT & Claim Hbar
                                    </Button>
                                    <Button onClick={() => onclickClaimNft(item)}
                                        variant='contained'
                                        sx={{
                                            height: '32px',
                                            margin: '4px 0',
                                            textTransform: 'none',
                                            backgroundColor: MAIN_BUTTON_COLOR,
                                            boxShadow: 'none',
                                            borderRadius: '16px',
                                            fontFamily: DEFAULT_FONT,
                                            '&:hover': {
                                                backgroundColor: MAIN_BUTTON_COLOR,
                                                boxShadow: 'none',
                                            },
                                            '&:focus': {
                                                boxShadow: 'none',
                                            },
                                        }}>
                                        Claim NFT
                                    </Button>
                                </Box>
                            </Box>
                        })
                    }
                    {
                        giftsFrom === GIFTS_IN_WALLET &&
                        walletNftData.length > 0 &&
                        walletNftData.map((item, index) => {
                            return <Box sx={{
                                position: 'relative',
                                width: 240,
                                height: 290,
                                minWidth: 240,
                                minHeight: 200,
                                backgroundColor: 'gray',
                                backgroundImage: `url(${item.imageUrl})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'top',
                                backgroundRepeat: 'no-repeat',
                                border: '10px solid',
                                borderColor: MAIN_BUTTON_BORDER_COLOR,
                                borderRadius: 0,
                                margin: '0 10px',
                            }}>
                                <Box sx={{
                                    width: 120,
                                    padding: '5px 10px',
                                    position: 'absolute',
                                    bottom: '50px',
                                    left: 0,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    backgroundColor: 'gray',
                                    borderTopRightRadius: '32px',
                                }}>
                                    {
                                        item.linkType === LINK_TYPE_TWITTER &&
                                        <svg xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'
                                            fill='white'
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}>
                                            <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z' />
                                        </svg>
                                    }
                                    {
                                        item.linkType === LINK_TYPE_EMAIL &&
                                        <Box
                                            style={{
                                                width: 20,
                                                height: 20,
                                                background: "url(./images/gmail.png)",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        />
                                    }
                                    <p style={{
                                        width: 90,
                                        margin: '0 0 0 5px',
                                        color: 'white',
                                        fontFamily: DEFAULT_FONT,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>{item.senderId}</p>
                                </Box>
                                <Button variant='contained'
                                    sx={{
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                        margin: 0,
                                        padding: 0,
                                        borderRadius: '20px',
                                        color: 'white',
                                        boxShadow: 'none',
                                        backgroundColor: MAIN_BUTTON_COLOR,
                                        fontSize: '12px',
                                        fontFamily: DEFAULT_FONT,
                                        '&:hover': {
                                            backgroundColor: MAIN_BUTTON_COLOR,
                                            boxShadow: 'none',
                                        },
                                        '&:focus': {
                                            boxShadow: 'none',
                                        },
                                    }}>
                                    {item.hbarAmount} Hbar
                                </Button>
                                <Box sx={{
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}>
                                    <Button onClick={() => onClickSendBurnAndClaim(item)}
                                        variant='contained'
                                        sx={{
                                            height: '32px',
                                            margin: '4px 0',
                                            textTransform: 'none',
                                            backgroundColor: MAIN_BUTTON_COLOR,
                                            boxShadow: 'none',
                                            borderRadius: '16px',
                                            fontFamily: DEFAULT_FONT,
                                            '&:hover': {
                                                backgroundColor: MAIN_BUTTON_COLOR,
                                                boxShadow: 'none',
                                            },
                                            '&:focus': {
                                                boxShadow: 'none',
                                            },
                                        }}>
                                        Burn NFT & Claim Hbar
                                    </Button>
                                </Box>
                            </Box>
                        })
                    }
                </Box>
            </div>
            <Backdrop
                sx={{
                    backgroundColor: 'black',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loadingViewFlag}
            >
                <div style={{
                    width: 250,
                    height: 225,
                    backgroundImage: 'url(./images/loading.gif)',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }} />
            </Backdrop>
            <ToastContainer autoClose={3000} draggableDirection='x' />
        </>
    );
}

export default Claim;