import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBqoxAGVNIaVOVwYeqNAK5HalhB7-MADao",
  authDomain: "react-twitter-b97cc.firebaseapp.com",
  projectId: "react-twitter-b97cc",
  storageBucket: "react-twitter-b97cc.appspot.com",
  messagingSenderId: "1066506259859",
  appId: "1:1066506259859:web:16abff82458bad7471ac66"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth }

// https://practice-auth-dcb56.firebaseapp.com/__/auth/handler