import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HashPackConnectModal from '../HashPackConnectModal';
import { useHashConnect } from '../../api/HashConnectAPIProvider.tsx';
import { MAIN_BUTTON_COLOR, MAIN_LIGHT_COLOR } from '../../value/colors';
import { DEFAULT_FONT } from '../../value/fonts';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';

import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from '../../firebase';

// import TwitterLogin from './TwitterLogin';
import { useCookies } from 'react-cookie';

const CONNECT_BUTTON_STYLE = {
  width: 180,
  height: 48,
  borderRadius: 0,
  margin: '20px 0',
  textTransform: 'none',
  fontSize: 16,
  fontFamily: DEFAULT_FONT,
  backgroundColor: MAIN_BUTTON_COLOR,
  '&:hover': {
    backgroundColor: MAIN_BUTTON_COLOR,
  },
};

function SignInModal() {
  const [walletConnectModalViewFlag, setWalletConnectModalViewFlag] = useState(false);
  const { walletData, installedExtensions, connect, disconnect } = useHashConnect();

  const { accountIds } = walletData;
  const [cookies, setCookie] = useCookies(['user']);
  const customHeader = {};

  // setCookie('user', []);
  customHeader['Test'] = 'test-header';

  useEffect(() => {
    console.log('useEffect', cookies, cookies.user);
  }, []);

  const onClickConnectHashPack = async () => {
    console.log('onClickConnectHashPack log - 1');
    if (installedExtensions) {
      connect();
      setWalletConnectModalViewFlag(false);
    } else {
      setWalletConnectModalViewFlag(false);
      toast.error(
        'Please install HashPack wallet extension first. from chrome web store.'
      );
      await sleep(1000 * 2);
      window.open(
        'https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk',
        '_blank'
      );
    }
  };

  const onClickDisconnectHashPack = () => {
    disconnect();
    setWalletConnectModalViewFlag(false);
  };

  const onClickCopyPairingStr = () => {
    navigator.clipboard.writeText(walletData.pairingString);
  };

  const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const onClickGoogleLogout = () => {
    if (cookies.user?.email) {
      console.log(cookies.user);
      googleLogout();
      setCookie('user', []);
    }
  };

  const onClickGoogleLogin = useGoogleLogin({
    onSuccess: async (res) => {
      console.log('onClickGoogleLogin log - 1 : ', res);
      const { data } = await axios({
        url: 'https://www.googleapis.com/oauth2/v3/userinfo',
        method: 'get',
        headers: {
          Authorization: `Bearer ${res.access_token}`,
        },
      });
      console.log('onClickGoogleLogin log - 2 : ', data);

      if (cookies.user?.twitter) {
        setCookie('user', []);
      }
      setCookie('user', data);

      return data;
    },
    onError: () => {
      console.log('onClickGoogleLogin log - 3 : Login Failed!');
    },
    scope:
      'https://www.googleapis.com/auth/cloud-platform https://www.googleapis.com/auth/dialogflow https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    overrideScope: true,
  });

  const onTwitterSuccess = (response) => {
    response.json().then((body) => {
      console.log('*****************TwitterInfo', body);
      if (cookies.user?.email) {
        onClickGoogleLogout();
      }
      setCookie('user', body);
    });
  };

  const onTwitterFailed = (error) => {
    alert(error);
  };





  // new code for twitter 


  const signInWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider).then((body) => {

      console.log('*****************TwitterInfo', body);
      if (cookies.user?.email) {
        onClickGoogleLogout();
      }
      setCookie('user', body);
      console.log(cookies.user);

    }).catch((err) => {
      console.log('Error', err);
    })
  }


  const signOutWithTwitter = () => {
    if (cookies.user?.email) {
      console.log("1212ddsfs",cookies.user);
      setCookie('user', []);
    }
  };




  return (
    <>
      <div style={{
        width: '80vw',
        maxWidth: 500,
        height: 300,
        background: `linear-gradient(0deg, ${MAIN_BUTTON_COLOR} 0%, ${MAIN_LIGHT_COLOR} 100%)`,
        border: '2px solid white',
        borderRadius: 16,
      }}
      >
        <div style={{
          height: 'calc(100% - 40px)',
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <Button
            onClick={() => {
              setWalletConnectModalViewFlag(true);
            }}
            variant='contained'
            sx={CONNECT_BUTTON_STYLE}
          >
            <div style={{
              width: 24,
              height: 24,
              marginRight: '5px',
              background: 'url(./images/hashpack.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
            />
            {accountIds?.length > 0 ? accountIds[0] : 'HashPack'}
          </Button>
          <button 
          
          onClick={() =>
            cookies.user?.email ? signOutWithTwitter() :  signInWithTwitter()
          }
          

          style={{
            width: 180,
            height: 48,
            background: 'url(./images/twitter.png)',
            fontSize:'14px',
            boxShadow:"0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            border:'none',
            backgroundColor:"#5646fe",
            backgroundRepeat:"no-repeat",
            backgroundSize:"30px",
            backgroundPosition:"15px 10px",
            color:"white",
            paddingLeft:"30px",
            cursor:"pointer"
          }}
          >
            {cookies.user?.email ? 'Log out with twitter' : 'Log in with twitter'}
          
          </button>
          {/* <TwitterLogin
            loginUrl='http://95.216.88.188:4000/api/v1/auth/twitter'
            onFailure={onTwitterFailed}
            onSuccess={onTwitterSuccess}
            requestTokenUrl='http://95.216.88.188:4000/api/v1/auth/twitter/reverse'
            showIcon={true}
            customHeaders={customHeader}
            forceLogin={true}
            children={cookies.user?.twitter ? true : false}
            style={{
              backgroundColor: MAIN_BUTTON_COLOR,
              color: 'white',
              width: 180,
              height: 48,
              border: 'none',
              boxShadow:
                '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              cursor: 'pointer',
            }}
          /> */}
          <Button
            variant='contained'
            sx={CONNECT_BUTTON_STYLE}
            onClick={() =>
              cookies.user?.email ? onClickGoogleLogout() : onClickGoogleLogin()
            }
          >
            <div style={{
              width: 24,
              height: 24,
              marginRight: '5px',
              background: 'url(./images/gmail.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
            />
            {cookies.user?.email ? 'Google Sign out ' : 'Google Sign in'}
          </Button>
        </div>
      </div>
      <Modal
        open={walletConnectModalViewFlag}
        onClose={() => {
          setWalletConnectModalViewFlag(false);
        }}
        centered={true}
      >
        <HashPackConnectModal
          pairingString={walletData.pairingString}
          connectedAccount={accountIds}
          onClickConnectHashPack={onClickConnectHashPack}
          onClickCopyPairingStr={onClickCopyPairingStr}
          onClickDisconnectHashPack={onClickDisconnectHashPack}
        />
      </Modal>
      <ToastContainer autoClose={5000} draggableDirection='x' />
    </>
  );
}

export default SignInModal;
