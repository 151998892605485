import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button } from "@mui/material";

import { MAIN_BUTTON_BORDER_COLOR, GREEN_FONT_COLOR } from "../value/colors";
import { DEFAULT_FONT } from "../value/fonts";

const MAIN_BUTTON_STYLE = {
    width: 240,
    height: 72,
    textTransform: 'none',
    fontFamily: DEFAULT_FONT,
    fontSize: 28,
    color: 'white',
    borderRadius: '16px',
    border: '2px solid',
    borderColor: MAIN_BUTTON_BORDER_COLOR,
    margin: '5px 0',
    '&:hover': {
        border: '2px solid',
        borderColor: MAIN_BUTTON_BORDER_COLOR,
    },
}

function Main() {
    let history = useHistory();
    const [cookies, setCookie] = useCookies(["user"]);

    const [signInFlag, setSignInFlag] = useState(false);

    useEffect(() => {
        if (cookies.user && Object.keys(cookies.user).length > 0) {
            console.log('CreateGift useEffect log - 1 : ', cookies.user);
            setSignInFlag(true);
        } else {
            setSignInFlag(false);
        }
    }, [cookies]);

    return (
        <>
            <div style={{
                width: '100vw',
                height: '30vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '40vh 0 30vh',
            }}>
                <Button onClick={() => {
                    if (signInFlag)
                        history.push('/create');
                    else
                        toast.warning('Please sign in with Twitter or Email.');
                }}
                    variant='outlined'
                    sx={MAIN_BUTTON_STYLE}>
                    <div style={{
                        width: 32,
                        height: 32,
                        marginRight: 16,
                        backgroundImage: 'url(./images/gift_new.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom',
                    }} />
                    Create
                </Button>
                <Button onClick={() => {
                    if (signInFlag)
                        history.push('/claim');
                    else
                        toast.warning('Please sign in with Twitter or Email.');
                }}
                    variant='outlined'
                    sx={MAIN_BUTTON_STYLE}>
                    <div style={{
                        width: 32,
                        height: 32,
                        marginRight: 16,
                        backgroundImage: 'url(./images/gift.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                    }} />
                    Claim
                </Button>
                <Button variant='outlined'
                    sx={{
                        width: 240,
                        height: 72,
                        textTransform: 'none',
                        fontFamily: DEFAULT_FONT,
                        fontSize: 28,
                        color: GREEN_FONT_COLOR,
                        borderRadius: '16px',
                        border: '2px solid',
                        borderColor: MAIN_BUTTON_BORDER_COLOR,
                        margin: '5px 0',
                        '&:hover': {
                            border: '2px solid',
                            borderColor: MAIN_BUTTON_BORDER_COLOR,
                        },
                    }}>
                    Tutorial
                </Button>
            </div>
            <ToastContainer autoClose={3000} draggableDirection='x' />
        </>
    );
}

export default Main;