import { createSlice } from '@reduxjs/toolkit';

export const sendNft = createSlice({
    name: 'offerData',

    initialState: {
        offerData: {}
    },

    reducers: {
        setOfferData: (state, action) => {
            state.offerData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setOfferData } = sendNft.actions
export default sendNft.reducer