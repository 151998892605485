import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import BasicWidgets from "./components/BasicWidgets";

import Main from "./pages/main";
import CreateGift from "./pages/createGift";
import CreateSuccess from "./pages/createSuccess";
import Claim from "./pages/claim";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { CookiesProvider, useCookies } from "react-cookie";

import store from "./store";

function App() {
  const client_id =
    "998124243359-hl8raokihqag5njs8frq9n5ef5v0lvba.apps.googleusercontent.com";

  return (
    <>
      <CookiesProvider>
        <GoogleOAuthProvider clientId={client_id}>
          <Provider store={store}>
            <BrowserRouter>
              <BasicWidgets />
              <Switch>
                <Route path="/main" render={(props) => <Main {...props} />} />
                <Route
                  path="/create"
                  render={(props) => <CreateGift {...props} />}
                />
                <Route
                  path="/success"
                  render={(props) => <CreateSuccess {...props} />}
                />
                <Route path="/claim" render={(props) => <Claim {...props} />} />
                <Redirect to="/main" />
                <Redirect from="/" to="/main" />
              </Switch>
            </BrowserRouter>
          </Provider>
        </GoogleOAuthProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
